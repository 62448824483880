<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-6">
        <h4 class="f-bold pt-2 pl-3 cl-primary text-left">Notification</h4>
      </div>
      <div class="col-12 col-md-6">
        <nav aria-label="breadcrumb">
          <ol
            class="breadcrumb float-right"
            style="background-color: transparent"
          >
            <li class="breadcrumb-item">
              <router-link to="/admin/dashboard">Dashboard</router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Notification
            </li>
          </ol>
        </nav>
      </div>
    </div>
    <div class="d-flex justify-content-around" v-if="loading">
      <loader-component></loader-component>
    </div>
    <div class="row pt-3" v-if="!loading">
      <div v-if="data.length == 0" class="col-md-12">
        <p class="text-secondary">Notification is empty</p>
      </div>
      <div class="col-md-12" v-for="(notification, n) in data" :key="n">
        <div
          class="container-fluid text-left mb-3 b-bottom"
          style="cursor: pointer"
          @click.prevent="
            toDetailPackages(
              notification.code,
              notification.package_id,
              notification.id
            )
          "
        >
          <h6>
            <span class="cl-primary f-bold">{{ notification.code }}</span>
            <span class="text-secondary"> | {{ notification.title }}</span>
            <span
              class="material-icons text-danger"
              v-if="notification.is_read == '0'"
            >
              fiber_new
            </span>
          </h6>
          <p class="cl-secondary">
            {{ notification.description }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LoaderComponent from "../../components/Loader.vue";
export default {
  name: "AdminNotification",
  components: { LoaderComponent },
  data() {
    return {
      loading: true,
      data: [],
      role: localStorage.getItem("role"),
    };
  },

  mounted() {
    this.getNotification();
  },

  methods: {
    getNotification() {
      const endpoint = "notification/admin";
      this.$api.get(endpoint, (status, data, message) => {
        if (status === 200) {
          this.loading = false;
          this.data = data.notification;
          console.log(this.data);
        } else {
          this.loading = false;
          console.log(message);
        }
      });
    },

    toDetailPackages(code, id, notif_id) {
      this.readNotification(notif_id);

      if (this.role == "finance") {
        this.$router.push(
          "/admin-finance/customer-packages/detail/" + code + "/" + id
        );
      } else if (this.role == "admin") {
        this.$router.push("/admin/customer-packages/detail/" + code + "/" + id);
      }
    },

    readNotification(idnotif) {
      const endpoint = "notification/read/" + idnotif;
      this.$api.post(endpoint, (status, message) => {
        if (status == 200) {
          console.log(message);
          this.getNotification();
        }
      });
    },
  },
};
</script>
